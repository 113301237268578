.drawer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: black;
    color: white;
    top: 8rem;
    position: fixed;
    width: 100%
}

.item{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #ffffff40;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
    border-top: solid 1px rgb(152 151 151 / 25%);
    text-decoration: none;
    color: white;
    text-align: center;
}

.item:hover{
    background-color: rgba(40, 40, 40, 0.55);
    cursor: pointer;
}

.item img{
    height: 3rem;
    width: 3rem;
    
    
}

.active{
    background-color: #fca220;
}

.active:hover{
    background-color: #fca220;
}


@media only screen and (max-width:620px){
    .item{
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #ffffff40;
        border-bottom: solid 1px rgba(255, 255, 255, 0.1);
        border-top: solid 1px rgb(152 151 151 / 25%);
    }
    .item img{
        height: 2rem;
        width: 2.5rem;
    }
}