.container{
    min-height: 100vh;
    padding: 1rem;
    padding-top: 10rem;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.title{
    text-align: center;
    padding: 1rem;
}

.games{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1.5rem;
}

.item{
    height: 24rem;
    text-decoration: none;
}

.img{
    height: 80%;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,.3) !important;
}

.name{
    /* height: 100%; */
    width: 100%;
    text-align: center;
    font-weight: 600;
    color: #0a86ff;
    font-size: 1.2rem;
}

.animation{
    height: 100%;
    width: 100%;
}

.noData{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
@media only screen and (max-width:780px){
    .item{
        height: 15rem;
    }
}

@media only screen and (max-width:428px){
    .item{
        height: 12rem;
    }
    .games{
        display: grid;
        grid-template-columns:  1fr 1fr;
        gap: 1rem;
    }
}