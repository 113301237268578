.container{
  height: 21rem;
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper_slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    height: 18rem;
    width: 100%;
  }
  
  .swiper_slide img {
    display: block;
    width: 100%;
    height: 16rem;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,.3) !important;
  }

  
  

  .link{
    height: 100%;
    width: 100%;
  }
  
  
  .name{
    color: #0a86ff;
    font-weight: 600;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    max-width: 100%;  
    white-space: nowrap;
    overflow: hidden;
    line-height: 20px;
    padding-bottom: 10px;
  }

  


  @media only screen and (max-width:728px){
    .container{
        height: 16rem;
      }

      .swiper_slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        height: 14rem;
        width: 100%;
      }
      
      .swiper_slide img {
        display: block;
        width: 100%;
        height: 12rem;
        object-fit: cover;
        border-radius: 10px;
       
      }
    

     
  }

  @media only screen and (max-width:480px){
    .container{
        height: 14rem;
      }

      .swiper_slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        height: 11rem;
        width: 100%;
      }
      
      .swiper_slide img {
        display: block;
        width: 100%;
        height: 8rem;
        object-fit: cover;
        border-radius: 10px;
      }
    

     
  }