.container{
    position: relative;
}


.navbar{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
}

.footer{
    /* position: static; */
    bottom: 0;
    width: 100%;
    z-index: 110;
}

.backToTopBtn{
    width: 130px;
    height: 34px;
    border-radius: 18px;
    padding: 0.2rem 0.8rem;
    font-size: 1.2rem;
    z-index: 99;
    left: 50%;
    margin-left: -65px;

    bottom: 3rem;
    position: fixed;
    /* text-align: center; */
    line-height: 32px;
    background: linear-gradient(to bottom, #499bea 0%, #207ce5 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.backToTopBtn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 25px;
    padding: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: white;
    width: 9rem;
  }

  
  .backToTopBtn:hover {
    background-color: #f0f0f0;
  }