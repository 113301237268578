.container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    color: white;
    height: 8rem;
    padding: 0rem;
    z-index: 99;
}

.menu{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
}

.logo_div{
    height: 100%;
    width: 75%;
    /* flex-grow: 1; */
    display: flex;
    justify-content: center;
    align-items: center;

}

.icon{
    cursor: pointer;
    z-index: 99;
    padding: .25rem;
}

.Search{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 15%;
    gap: 2rem;
}

.input{
    border: none;
    padding: 0.5rem 1rem;
    display: none;
    background: rgba(255, 255, 255, 0.361);
    border-radius: 20px;
    width: 100%;
    color: white;
    font-size: 1.2rem;
}

.input:focus{
    border: none;

}

.inputopen{
    display: block;
}

.logo{
    height: 100%;
}


.userdetails{
    position: absolute;
    z-index: 999;
    top: 5.5rem;
    right: 2%;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    font-size: 1.5rem;
    /* height: 10rem; */
    width: 12rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 1.5rem;
    display: none;
    
}

.icons{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.account{
    display: block;
}

.close_icon{
    position: absolute;
    top:7%;
    right: 1rem;
    cursor: pointer;
}

.line{
    border: 1px solid black;
    width: 100%;
}
.text{
    text-align: left;
    padding: 0.5rem;
}

.logOut{
    border: none;
    padding: 1rem;
    text-align: center;
    background-color: rgb(160, 14, 14);
    color: white;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-top: 10px;
    cursor: pointer;
    font-size: 1.2rem;
    
}

.logOut:hover{
    background-color: rgb(145, 15, 15);
}


@media only screen and (max-width:1180px) {
    .Search{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 30%;
    }
}


@media only screen and (max-width:876px){
    .Search{
        width: 40%;
    }
}


@media only screen and (max-width:676px){
    .Search{
        flex-direction: column-reverse;
        width: 50%;
        gap: 0.5rem;
    }



    .input{
        width: 80%;
    }

    .icon{
        cursor: pointer;
        z-index: 99;
        padding: .25rem;
    }
}