.container{
    min-height: 100vh;
    padding: 1rem;
    padding-top: 10rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.loader{
    background-color: black;
    height: 100vh;
}

.animation{
    height: 100%;
    width: 100%;
}

.noData{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}