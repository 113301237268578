/* .outer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem;
} */

.modal{
    display: none; /* Hidden by default */
    position: fixed;
    z-index: 1000;
    left: 5%;
    top: 10%;
    border-radius: 10px;
    width: 90%;
    height: 88%;
    bottom: 10%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
    transition: opacity 0.3s ease;
    backdrop-filter: unset;
    scrollbar-width: none;
    backdrop-filter: brightness(5%);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    backdrop-filter: brightness(0.5);
}

.show{
    display: block;
    position: fixed;
}

.container{
    font-family:"Times New Roman",serif;
    color:#212529;
    padding: 2rem;
    font-weight: 500;
    /* font-size: 1rem; */
    text-align:justify;
    background-color: white;
    

}

.title{
    font-size:1.5rem;
    text-align:center;
    line-height:150%;
}

.intro{
    font-size:1.1rem;
    line-height:150%;
}

.sub_title{
    font-size: 1.2rem;
}

.item{
    font-size:1.1rem;
    line-height:150%;
}

.btn_div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    border-radius: 5px;
    font-size: 1.2rem;
    background-color: #3085d6;
    color: white;
    border: none;
    cursor: pointer;
}