.container{
    min-height: 100vh;
    width: 100%;
    
}

.navbar{
    width: 100%;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.navbar img{
    height: 100%;

}

.footer{
    width: 100%;
}


.sub_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
    text-align: center;
    /* padding-top: 10rem ; */
}

.img{
    width: 100%;
    height: 20rem;
}

.img img{
    width: 100%;
    height: 100%;
}

.form_container{
    padding: 2rem;

}

.welcome{
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 200%;
}

.play{
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 200%;
}

.enter{
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 200%;
}

.input{
    width: 75%;
    padding: 1rem 2rem;
    border: none;
    border-radius: 30px;
    font-size: 1.2rem;
}

.tnc{
    line-height: 200%;
    font-size: 1.1rem;
}

.check{
    padding: 10px;
}

.btn{
    width: 50%;
    padding: 0.75rem;
    color: white;
    background-color: #4097b1;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1.2rem;
}

.btn:hover{
    background-color: #2b6a7d;   
}